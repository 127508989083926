<h1 *ngIf="isAddMode">Create Alert</h1>
<h1 *ngIf="!isAddMode">Edit Alert</h1>

<form [formGroup]="alertForm" class="flex flex-column">
  <div class="input-descriptor-label u-margin__bottom--tiny">1. Name the alert</div>
  <mat-form-field hideRequiredMarker>
    <mat-label>Name</mat-label>
    <input formControlName="name" type="text" matInput placeholder="Name" />
    <mat-error data-e2e="name-error" *ngIf="name.hasError('maxlength') && !name.hasError('required')">
      The name can not be more than 50 characters
    </mat-error>
  </mat-form-field>

  <div class="input-descriptor-label u-margin__bottom--tiny">2. Select alert type</div>
  <div *ngIf="alertTypes$ | withLoading | async as alertTypes" class="alert-type-container">
    <ng-template [ngIf]="alertTypes.value">
      <zui-searchable-dropdown
        label="Type"
        [options]="alertTypes.value.alertTypeOptions"
        [isMultiple]="false"
        [z_formGroup]="alertForm"
        [selectOptionsControlName]="'type'"
        [hideRequiredMarker]="true"
        (selected)="handleAlertTypeSelection($event, alertTypes.value.severityOptionsByType)"
      >
      </zui-searchable-dropdown>
      <div
        class="alert-description"
        *ngIf="alertForm.value.type && alertTypes.value.alertDescriptionByType[alertForm.value.type]"
        [innerHTML]="alertTypes.value.alertDescriptionByType[alertForm.value.type]"
      ></div>

      <div *ngIf="alertForm.value.type">
        <div
          class="flex flex-row items-center min-unauthorized-distance-container"
          *ngIf="alertForm.value.type === unauthorizedUsageAlertTypeValue"
        >
          <span>Alert after asset has driven </span>
          <mat-form-field hideRequiredMarker class="min-unauthorized-distance">
            <input
              required
              formControlName="unauthorizedUsageDistance"
              type="text"
              matInput
              placeholder="0"
              (keypress)="omitNumbersFromInput($event)"
              maxlength="1"
            />
          </mat-form-field>
          <span>miles</span>
        </div>

        <span *ngIf="alertTypesWithRadioButtons.includes(alertForm.value.type)">
          <fieldset class="flex flex-column justify-between severity-options-container">
            <legend>Threshold options</legend>
            <mat-radio-group formControlName="severity">
              <mat-radio-button
                *ngFor="let severityOption of alertTypes.value.severityOptionsByType[alertForm.value.type]"
                [value]="severityOption.value"
                (change)="handleToggleStatusChange()"
              >
                <div class="flex flex-row justify-between items-center">
                  <div class="custom-icon {{ severityOption.icon }}">
                    <mat-icon>{{ severityOption.icon }}</mat-icon>
                  </div>
                  <div class="flex flex-column justify-between severity-content">
                    <span class="severity-title">{{ severityOption.title }}</span>
                    <span class="severity-description">{{ severityOption.description }}</span>
                  </div>
                </div>
              </mat-radio-button>
            </mat-radio-group>
            <ng-container *ngIf="displayCTSLink && (isManagedThresholds$ | async)">
              <a *ngIf="alertTypes.value.isThresholdUrlShowedByType[alertForm.value.type]" [href]="thresholdSettingsLink"
                >Update threshold settings</a
              >
            </ng-container>
          </fieldset>
        </span>
      </div>
    </ng-template>
    <ng-template [ngIf]="alertTypes.error">
      <div class="dropdown-error-container u-margin__bottom--medium">Error loading alert types</div>
    </ng-template>
    <ng-template [ngIf]="alertTypes.loading">
      <ngx-skeleton-loader
        count="1"
        [theme]="{
          'border-radius': '3px',
          height: '40px',
          'background-color': '#E3E3E3'
        }"
      ></ngx-skeleton-loader>
    </ng-template>
  </div>

  <div *ngIf="displayCustomSchedule" class="flex flex-row justify-center add-custom-schedule-container">
    <div *ngIf="alertForm.value.startTime && alertForm.value.endTime; then viewCustomSchedule; else addCustomSchedule"></div>
  </div>

  <div class="input-descriptor-label u-margin__bottom--tiny">3. Select asset(s)</div>
  <span *ngIf="preselectedAssetsObs$ | withLoading | async as assets">
    <ng-template [ngIf]="assets.value">
      <zui-asset-filter
        [companyId]="companyId$ | async"
        [zonarOwnerId]="null"
        [showApplyButton]="false"
        [compactMode]="false"
        (selectedAssetList)="handleAssetDropdownChange($event)"
        [preSelectedAssets]="assets.value"
        class="asset-filter"
      >
      </zui-asset-filter>
    </ng-template>

    <ng-template [ngIf]="assets.error"
      ><div class="dropdown-error-container u-margin__bottom--medium">Error loading assets</div></ng-template
    >
    <ng-template [ngIf]="assets.loading">
      <ngx-skeleton-loader
        count="1"
        [theme]="{
          'border-radius': '3px',
          height: '40px',
          'background-color': '#E3E3E3'
        }"
      ></ngx-skeleton-loader>
    </ng-template>
  </span>
  <input type="hidden" formControlName="assets" />

  <div class="input-descriptor-label u-margin__bottom--tiny">4. Select internal recipient(s) to receive email alerts</div>
  <div *ngIf="recipients$ | withLoading | async as recipients">
    <ng-template [ngIf]="recipients.value">
      <zui-searchable-dropdown
        label="Recipients"
        [options]="recipients.value"
        [isMultiple]="true"
        [z_formGroup]="alertForm"
        [selectOptionsControlName]="'recipients'"
        [hideRequiredMarker]="true"
      >
      </zui-searchable-dropdown>
    </ng-template>
    <ng-template [ngIf]="recipients.error"
      ><div class="dropdown-error-container u-margin__bottom--medium">Error loading recipients</div></ng-template
    >
    <ng-template [ngIf]="recipients.loading">
      <ngx-skeleton-loader
        count="1"
        [theme]="{
          'border-radius': '3px',
          height: '40px',
          'background-color': '#E3E3E3'
        }"
      ></ngx-skeleton-loader>
    </ng-template>
  </div>

  <div *ngIf="!isAddMode" class="flex flex-row justify-between active-status-toggle-container u-margin__bottom--medium striped">
    <mat-slide-toggle formControlName="isActive" (change)="handleToggleStatusChange()">
      {{ alertForm.value.isActive ? 'Active' : 'Inactive' }}
    </mat-slide-toggle>
    <mat-icon #tooltip="matTooltip" matTooltip="Recipients will not receive alerts when set to inactive" [matTooltipPosition]="'above'">
      info_outline
    </mat-icon>
  </div>
  <div class="flex flex-row justify-between">
    <span>
      <button *ngIf="!isAddMode" type="button" mat-stroked-button color="accent" (click)="deleteAlert()">
        <mat-icon>delete</mat-icon>Delete
      </button>
    </span>
    <span>
      <button mat-stroked-button type="button" color="accent" class="button-margin__right" routerLink="/">Cancel</button>
      <button
        mat-flat-button
        type="submit"
        data-e2e="save-button"
        color="accent"
        (click)="submit()"
        [disabled]="!alertForm.valid || !alertForm.touched || !alertForm.dirty || isDropdownLoadingError || isSaving"
      >
        {{ isSaving ? 'Saving' : 'Save' }}
      </button>
    </span>
  </div>
</form>

<ng-template #addCustomSchedule>
  <button mat-stroked-button color="accent" type="button" class="add-custom-schedule-button" (click)="openCustomScheduleDialog()">
    <mat-icon>add</mat-icon>
    <span>Add custom schedule</span>
  </button>
</ng-template>

<ng-template #viewCustomSchedule>
  <mat-card [style.width.%]="100">
    <mat-card-content class="flex-row justify-between items-center">
      <div class="flex flex-column custom-shedule-info">
        <div>Custom schedule:</div>
        <div>
          {{ alertForm.value.startTime }} - <span>{{ alertForm.value.endTime }}</span>
        </div>
      </div>
      <button mat-stroked-button color="accent" type="button" class="edit-custom-schedule-button" (click)="openCustomScheduleDialog()">
        Edit
      </button>
    </mat-card-content>
  </mat-card>
</ng-template>
