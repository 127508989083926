import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENV } from '@environments/environment.provider';
import { httpRetryCount } from '@environments/shared';
import { Observable, throwError, EMPTY } from 'rxjs';
import { CompanyService } from './company-store.service';
import { filter, map, switchMap, catchError, expand, reduce } from 'rxjs/operators';
import { Recipient } from '@app/models/subscription.model';
import { retry } from 'rxjs/operators';

interface PaginatedResponse<T> {
  items: T[];
  nextPage?: number;
}

@Injectable({
  providedIn: 'root'
})
export class RecipientApiHttpService {
  USERS_API_URL = this.env.coreEntityApiBase.url + '/users';
  APP_ID = this.env.auth.applicationId;

  constructor(@Inject(ENV) private env: any, private _companyService: CompanyService, private _httpService: HttpClient) {}

  getRecipients(): Observable<Map<string, Recipient>> {
    return this._companyService.currentCompany$.pipe(
      filter((company) => !!company),
      switchMap((company) => this.getAllUsers(company.value)),
      map((users) => {
        // Create an array from the users with the desired structure
        const recipients = users.map((user) => ({
          userId: user.id,
          recipient: {
            name: this._capitalizeFullName(`${user.firstName} ${user.lastName}`),
            userId: user.id
          }
        }));
  
        // Sort the array by the recipient's name
        recipients.sort((a, b) => a.recipient.name.localeCompare(b.recipient.name));
  
        // Convert the sorted array back into a map
        const recipientMap = new Map<string, Recipient>();
        recipients.forEach(({ userId, recipient }) => {
          recipientMap.set(userId, recipient);
        });
  
        return recipientMap;
      }),
      catchError((error) => {
        console.error('Failed to load recipients', error);
        return throwError(() => new Error('Failed to load recipients'));
      })
    );
  }
  
  getAllUsers(companyValue: string): Observable<any[]> {
    const userUrl = `${this.USERS_API_URL}?companyId=${companyValue}&status=ACTIVE`;
    return this.getPaginatedData<any>(userUrl, 1).pipe(
      expand(data => {
        return data.nextPage ? this.getPaginatedData<any>(userUrl, data.nextPage).pipe(
          catchError(error => {
            console.error('Error fetching page: ', data.nextPage, error);
            return throwError(() => new Error('Error fetching page'));
          })
        ) : EMPTY;
      }),
      reduce((acc, data) => {
        return acc.concat(data.items);
      }, [] as any[])
    );
  }  

  private getPaginatedData<T>(url: string, page: number, perPage: number = 1000): Observable<PaginatedResponse<T>> {
    const paginatedUrl = `${url}&per_page=${perPage}&page=${page}`;
  
    return this._httpService.get<T[]>(paginatedUrl).pipe(
      retry(httpRetryCount),
      map(items => {
        const hasNextPage = items.length === perPage;
        return {
          items,
          nextPage: hasNextPage ? page + 1 : undefined
        };
      })
    );
  }    

  private _capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  private _capitalizeFullName(name: string): string {
    return name.split(' ').map(this._capitalizeFirstLetter).join(' ');
  }
}
